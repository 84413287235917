 // core version + navigation, pagination modules:
 import Swiper, { Navigation, Pagination, Autoplay, EffectFade} from 'swiper';

 // configure Swiper to use modules
 Swiper.use([
   Pagination, 
   Navigation, 
   Autoplay,
   EffectFade
  ]);

 const swiper = new Swiper('.swiper-container', {
     // Optional parameters
    loop: true,
    slidesPerView:1,
    autoHeight:false,
    centeredSlides: true,
    effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
});
