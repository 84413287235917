// Based on the Height Of Slider We will Set .top-content-right sections hieght
var SlideShowHeight = $('.swiper-container').height();
$('.top-content-right').css('max-height',SlideShowHeight);


// On cicking anywhere in header
$('.top-logo-bar').click(function(){
    window.location.href = '/';
})

// DataTables
require( 'datatables.net' );

const dtLanguage = {
    search: "खोजनुहोस:",
    paginate: {
        first:      "प्रथम पेज",
        previous:   "अघिल्लो",
        next:       "पछिल्लो",
        last:       "अन्तिम पेज"
    },
    "emptyTable":     "डैटा प्रविष्टी नभयको",
    "info":           "कुल _TOTAL_ वटामा _START_ देखि _END_ सम्म देखाइयको",
    "lengthMenu":     "_MENU_ प्रविष्टिहरु देखाउनुहोस्",
    "infoEmpty":      "कुल ० वटामा ० देखि ० सम्म देखाइयको",
    "zeroRecords":    "खोजे अनुसार रेकर्ड भेटिएन",
    "loadingRecords": "लोडिन्ग...",
    "processing":     "प्रशोधन गर्दै...", 
};

$('#table_news-notices').DataTable({
    language: dtLanguage,
    responsive: true
});

// Bootstrap Dropdown
 // make it as accordion for smaller screens
 if (window.innerWidth > 992) {

    function showDropDown(everyitem){
        everyitem.addEventListener('mouseover', function(e){

            let el_link = this.querySelector('a[data-toggle]');

            if(el_link != null){
                let nextEl = el_link.nextElementSibling;
                el_link.classList.add('show');
                nextEl.classList.add('show');
            }
        });
        everyitem.addEventListener('mouseleave', function(e){
            let el_link = this.querySelector('a[data-toggle]');

            if(el_link != null){
                let nextEl = el_link.nextElementSibling;
                el_link.classList.remove('show');
                nextEl.classList.remove('show');
            }
        });
    }

    document.querySelectorAll('.navbar .nav-item').forEach(function(everyitem){
        showDropDown(everyitem);
    });
    document.querySelectorAll('.navbar .sub-nav-item').forEach(function(everyitem){
        showDropDown(everyitem);
    });

}